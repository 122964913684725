import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styles from "../styles/homePlaces.module.css"

import { useMediaQuery } from "react-responsive"
import { useScrollData } from "scroll-data-hook"
import Linea from "../images/linea.svg"
import Img from "gatsby-image"
import BlueCircle from "../images/logos/blueCircle.svg"

const Places = ({ data }) => {
  const {
    scrolling,
    time,
    speed,
    direction,
    position,
    relativeDistance,
    totalDistance,
  } = useScrollData({
    onScrollStart: () => {},
  })
  const [load, setLoad] = useState(false)
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  useEffect(() => {
    setLoad(true)
  }, [mobile])

  let QuilmesDesk = data.placesBackImageDesktop.childImageSharp.fluid
  let QuilmesMobile = data.placesBackImage.childImageSharp.fluid
  return load ? (
    <>
      <div className={`${styles.container} `}>
        <div className={mobile ? styles.hidden : styles.blueBack}>
          <img id={styles.blueCircle} src={BlueCircle} />
          <div
            className={
              position.y > 980
                ? `${styles.textContainerAnimation}`
                : `${styles.textContainer}`
            }
          >
            <h1>NUESTROS LUGARES</h1>
            <img id={styles.line} src={Linea} />
            <h5>
              Además de la Quilmes que tomás en tu casa queremos que disfrutes
              la que servimos en la nuestra, llega directamente de la cervecería
            </h5>

            <Link
              to="/places"
              onClick={() => {
                window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "Content",
                  event_action: "Nuestros Lugares",
                  event_label: `Conocer Lugares`,
                  interaction: true,
                  component_name: "boton_conocer_lugares",
                  element_text: "Conocer Lugares",
                })
              }}
            >
              <button className={styles.button}>CONOCER LUGARES</button>
            </Link>
          </div>
        </div>
        <Img
          fluid={!mobile ? QuilmesDesk : QuilmesMobile}
          className={!mobile ? styles.backImg : styles.backImgMobile}
          /* className={styles.backImg} */
        />
        <div className={!mobile ? styles.hidden : styles.textContainer}>
          <h1>NUESTROS LUGARES</h1>
          <img id={styles.line} src={Linea} />
          <h5>
            Además de la Quilmes que tomás en tu casa queremos que disfrutes la
            que servimos en la nuestra, llega directamente de la cervecería
          </h5>
          <Link to="/places">
            <button className={styles.button}>CONOCER LUGARES</button>
          </Link>
        </div>
      </div>
    </>
  ) : null
}

export default Places
