import React, { useState, useEffect } from "react"
import styles from "../styles/varieties.module.css"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Item = ({
  idx,
  data,
  lata,
  setBackgroundVarietiesImg,
  setName,
  setSlug,
}) => {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  const [activeLata, setActive] = useState(false)

  function handleMouseOver() {
    let sortedEdges = data.allBeer.edges.sort(function (a, b) {
      return a.node.order - b.node.order
    })

    if (data.allBeer.edges[lata.order].node.id === lata.id) {
      setBackgroundVarietiesImg(
        data.allBeer.edges[lata.order].node.back_image.childImageSharp.fluid
      )
    }

    setName(lata.name.toUpperCase())
    setActive(!activeLata)
  }

  return (
    <div index={lata.id} className={`${styles.beerVarieties}`}>
      {!mobile ? (
        <Link to={`/${lata.slug}`}>
          <img
            className={`${styles.canImg}`}
            onMouseOver={() => handleMouseOver()}
            onMouseLeave={() => setActive(!activeLata)}
            src={lata.canImg}
            alt="lata"
          />
        </Link>
      ) : (
        <img className={styles.canImg} src={lata.canImg} alt="lata" />
      )}

      <h3 className={!mobile ? null : styles.hidden}>
        {lata.name.toUpperCase()}
      </h3>

      <div className={mobile ? styles.hidden : styles.buttonContainer}>
        <button
          id={lata.id}
          className={activeLata ? styles.buttonActive : styles.button}
        >
          {/* <a target="_blank" href={lata.buyLink}> */}
          <a
            target="_blank"
            href={lata.buyLink}
            onClick={() => {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Product",
                event_action: "Content",
                event_label: `Comprar`,
                interaction: true,
                component_name: "boton_comprar",
                element_text: "Comprar",
                product: `${lata.name.toUpperCase()}`,
              })
            }}
          >
            COMPRAR
          </a>
        </button>
        <Link
          style={{ textDecoration: "none" }}
          className={styles.linkTo}
          to={`/${lata.slug.toLowerCase()}`}
          onClick={() => {
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Product",
              event_action: "Content",
              event_label: `Ver Más`,
              interaction: true,
              component_name: "boton_ver_mas",
              element_text: "Ver Más",
              product: `${lata.name.toUpperCase()}`,
            })
          }}
        >
          VER MÁS
        </Link>{" "}
      </div>
    </div>
  )
}

export default Item
