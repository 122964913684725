import React from "react"
import ArrowNext from "../images/logos/arrowNext.svg"
function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      style={{ ...style, display: "block", width: "1.5%", cursor: "pointer" }}
      onClick={onClick}
      src={ArrowNext}
    />
  )
}

export default NextArrow
