import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Item from "../components/item"
import styles from "../styles/varieties.module.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import PrevArrow from "../components/prevArrow"
import NextArrow from "../components/nextArrow"
import { useMediaQuery } from "react-responsive"
import "../styles/slider.css"
import { useScrollData } from "scroll-data-hook"
import { set } from "js-cookie"

const Varieties = ({ data }) => {
  const {
    scrolling,
    time,
    speed,
    direction,
    position,
    relativeDistance,
    totalDistance,
  } = useScrollData({
    onScrollStart: () => {},
  })

  const mobile = useMediaQuery({ query: "(max-width: 485px)" })

  const [name, setName] = useState("CLÁSICA")
  const [slug, setSlug] = useState("clasica")
  const [settings, setSettings] = useState({})
  const [buyLink, setBuyLink] = useState(
    "https://www.craftsociety.com.ar/collections/all-beers/products/cerveza-quilmes-clasica-lata?utm_source=quilmes&utm_medium=store&utm_campaign=always-on&utm_content=ad&utm_term=quilmes-clasica"
  )
  const [load, setLoad] = useState(false)
  const [backgroundVarietiesImg, setBackgroundVarietiesImg] = useState(
    data.defaultBack.childImageSharp.fluid
  )
  let sortedEdges = data.allBeer.edges.sort(function (a, b) {
    return a.node.order - b.node.order
  })
  let varieties = data.allBeer.nodes.sort(function (a, b) {
    return a.order - b.order
  })

  // let settings
  useEffect(() => {
    // console.log(mobile)
    if (mobile) {
      setSettings({
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSLide: true,
        arrows: false,
        centerMode: true,
        centerPadding: "-10px",
        /*    swipe: true,
        touchMove: true, */
        beforeChange: (oldindex, newIndex) => {
          setName(data.allBeer.nodes[newIndex].name.toUpperCase())
          setSlug(data.allBeer.nodes[newIndex].slug)
          setBuyLink(data.allBeer.nodes[newIndex].buyLink)
          setBackgroundVarietiesImg(
            sortedEdges[newIndex].node.back_image_mobile.childImageSharp.fluid
          )
        },
      })
      setLoad(true)
    } else {
      setSettings({
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 6,
        slidesToScroll: 6,
        nextArrow: <NextArrow></NextArrow>,
        prevArrow: <PrevArrow></PrevArrow>,
      })
      setLoad(true)
    }
  }, [mobile])
  // mobile
  //   ? (settings = {
  //       dots: false,
  //       infinite: true,
  //       speed: 1000,
  //       slidesToShow: 3,
  //       slidesToScroll: 1,
  //       swipeToSLide: true,
  //       arrows: false,
  //       centerMode: true,
  //       centerPadding: "-10px",
  //       /*    swipe: true,
  //       touchMove: true, */
  //       beforeChange: (oldindex, newIndex) => {
  //         setName(data.allBeer.nodes[newIndex].name.toUpperCase())
  //         setSlug(data.allBeer.nodes[newIndex].slug)
  //         setBuyLink(data.allBeer.nodes[newIndex].buyLink)
  //         setBackgroundVarietiesImg(
  //           sortedEdges[newIndex].node.back_image_mobile.childImageSharp.fluid
  //         )
  //       },
  //     })
  //   : (settings = {
  //       dots: false,
  //       infinite: true,
  //       speed: 1500,
  //       slidesToShow: 5,
  //       slidesToScroll: 5,
  //       nextArrow: <NextArrow></NextArrow>,
  //       prevArrow: <PrevArrow></PrevArrow>,
  //     })

  return (
    <>
      {
        <div style={{ position: "relative", height: "100vh" }}>
          <BackgroundImage
            className={styles.backImg}
            fluid={backgroundVarietiesImg}
          ></BackgroundImage>

          <div className={styles.blackOverlay}>
            <div className={`${styles.textContainer}`}>
              <h1
                className={
                  position.y > 300 ? `${styles.textTitleAnimation}` : null
                }
              >
                NUESTRAS VARIEDADES
              </h1>
              <h5
                className={position.y > 300 ? `${styles.textAnimation}` : null}
              >
                Así como la milanesa sale con fritas y el fresco con batata,
                cada una de nuestras variedades van bien con algún clásico plato
                argentino.
                <br />
                Clickeá en cualquiera de ellas para descubrir las mejores
                combinaciones.
              </h5>
            </div>

            {load && (
              <>
                <Slider
                  {...settings}
                  // style={{
                  //   marginLeft: "-20px",
                  //   // marginTop: "-60%",
                  //   marginBottom: "-40%",
                  //   border: "1px solid red",
                  //   height:"50vh"
                  // }}
                  className={
                    position.y > 330
                      ? `${styles.canContainerAnimation}`
                      : `${styles.canContainer}`
                  }
                >
                  {varieties.map((item, idx) => {
                    return (
                      <Item
                        key={item.name}
                        lata={item}
                        setName={setName}
                        data={data}
                        setBackgroundVarietiesImg={setBackgroundVarietiesImg}
                        setSlug={setSlug}
                        idx={idx}
                      ></Item>
                    )
                  })}
                </Slider>
                <div
                  className={mobile ? styles.buttonsContainer : styles.hidden}
                >
                  <h3 className={mobile ? null : styles.hidden}>{name}</h3>
                  <div className={styles.innerButtonContainer}>
                    <button className={styles.buttonActive}>
                      {/* <a target="_blank" href={`${buyLink}`}> */}
                      <a
                        target="_blank"
                        href={buyLink}
                        onClick={() => {
                          window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Product",
                            event_action: "Content",
                            event_label: `Comprar`,
                            interaction: true,
                            component_name: "boton_comprar",
                            element_text: "Comprar",
                            product: `${name}`,
                          })
                        }}
                      >
                        COMPRAR
                      </a>
                    </button>
                    <div
                      className={styles.buttonMore}
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        window.dataLayer.push({
                          event: "GAEvent",
                          event_category: "Product",
                          event_action: "Content",
                          event_label: `Ver Más`,
                          interaction: true,
                          component_name: "boton_ver_mas",
                          element_text: "Ver Más",
                          product: `${name}`,
                        })
                        navigate(`/${slug.toLowerCase()}`)
                      }}
                    >
                      VER MÁS
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        /*    </div> */
      }
    </>
  )
}
export default Varieties
